import React from 'react'
import Frame from './blogFrame'
import './less/global.less'


export default class ContatctPage extends React.Component{
    render(){
        return(
            <Frame type={"contact"}></Frame>
        )
    }
}