import React from 'react'
import AWS from "aws-sdk";
import "./awsUtil";
import { Timeline , Spin, Space} from 'antd';


const s3 = new AWS.S3({
    signatureVersion: "v2",
  });


export default class devPath extends React.Component{
    constructor(props){
        super();
        this.state = {
            fileName:"devlog.json",
            log:{},
            isLoading:true
        }
    }

    async componentDidMount(){
        var that = this;
        var params = { Bucket: "project-w-article", Key: this.state.fileName }; //此处bucket与文章bucket不同
        s3.getObject(params, function (err, data) {
            if (err) console.log(err, err.stack);
            else {
              var body = JSON.parse(data.Body.toString());
              //var htmlFormat = "";
              try {
                that.setState({
                  isLoading: false,
                  log: body,
                  

                  
                });
              } catch (error) {
                console.log(error);
              }
            }
          });
        }
    

    render(){
        console.log(this.state.log)
        if(this.state.isLoading === true){
            return <Space size="middle"><Spin size="large" /></Space>;

        }
        else if(this.state.isLoading === false){
            const tl = this.state.log;
        return(
            <Timeline
            mode="alternate"
            >
                {tl.map((item) => (
                    <Timeline.Item key={item.msg} label={item.time} color={item.color}><b style={{color:"orange"}}>{item.msg}</b></Timeline.Item>

                ))}

            </Timeline>
        )}
    }
}