import React from 'react';
import BlogFrame from './blogFrame.jsx'
//import './less/global.less'


export default class articlePage extends React.Component{

    render(){
        
        var title = this.props.match.url.replace("/article/","");
        console.log(title)
        return(
            <div>
                <BlogFrame title={title} type="article"/>
                </div>
        )
    }
}