import React from 'react';

//import "../util/Save.jsx"
//import * as Change from "../util/Change.js"




export default class MainMenu extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            rm : "张开双眼。",
            cr : 20
        }
    }

    handleChange = (name,val) => {
        var temp = this.state.cr;
        this.setState(
            { cr: temp+val ,
             rm: "你从一艘星舰残骸中找到了一些可用的物资。"},
            () => {
                console.log(this.state.cr);
            }
        );
    }

    render(){

  return (
    <div className="MainMenu">
     <h5>Repair ship rack</h5>
    <button onClick={(e) => this.handleChange("cr",2,e)} >Do</button>
     <h1>cr = {this.state.cr}</h1>
     <h5>{this.state.rm}</h5>
    </div>


)
}
}
