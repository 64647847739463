import React from 'react';
import BlogFrame from './blogFrame.jsx'


export default class listPage extends React.Component{
    render(){
        return(
            <div>
                <BlogFrame type="list"/>
            </div>
        )
    }
}