const config = {
    s3:{
        accessKeyId: 'AKIAV6FQIK7QZGHYUFKP',
        secretAccessKey: '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',
        signatureVersion:'v2',
        region:'us-east-1'    
    },
    aws_config:{
        accessKeyId: 'AKIAV6FQIK7QZGHYUFKP',
        secretAccessKey: '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',
        region:'us-east-1'
    
    }

}

export default config;
