import React from 'react';
import index from './blog/index.jsx'
import Game from './comp/MainMenu.jsx'
import ArticlePage from './blog/ArticlePage/articlePage.jsx'
import ListPage from './blog/ArticlePage/listPage.jsx'
import Contact from './blog/ArticlePage/ContatctPage.jsx'
import DevPath from './blog/ArticlePage/devPathPage.jsx'

import {BrowserRouter as Router,Route} from 'react-router-dom';

function router(){
    return(
        <Router>
            <Route exact path="/home"exact component={index}/>
            <Route exact path="/" component={index}/>
            <Route exact path="/blog" component={ListPage}/>
            <Route exact path="/p" component={Game}/>
            <Route path="/article/:aid" component={ArticlePage}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/article" component={ListPage}/>
            <Route exact path="/devPath" component={DevPath}/>
            



        </Router>
    )
}

export default router;