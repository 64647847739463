import React from "react";
import { Layout, Menu, Row, Col, BackTop } from "antd";
import {
  CompassOutlined,
  ShakeOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import "./less/Frame.less";
import ArticleContent from "./article.jsx";
import ListArticle from "./list.jsx";
import { NavLink } from "react-router-dom";
import Contatct from "./Contact";
import DevPath from './devPath.jsx'
import "./less/global.less";

const { Sider } = Layout;

export default class blogIndex extends React.Component {
  state = {
    collapsed: false,
    title: this.props.title,
    type: this.props.type,
    renderContent: <div></div>,
  };
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  async componentDidMount() {
    console.log(this.props.title);
    if (this.state.type === "article") {
      this.setState({
        renderContent: <ArticleContent title={this.props.title} />,
      });
    } else if (this.state.type === "list") {
      this.setState({
        renderContent: <ListArticle />,
      });
    } else if (this.state.type === "contact") {
      this.setState({
        renderContent: <Contatct />,
      });
    } else if (this.state.type === "log"){
      this.setState({
        renderContent: <DevPath />
      })
    }
  }

  render() {
    return (
      <div>
        <Layout className="topLayout1" style={{ minHeight: "25vh" }}></Layout>
        <Row>
          <Layout className="mainLayout" style={{ minHeight: "75vh" }}>
            <Sider
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
            >
              <div className="logo" />
              <div style={{height:"2vh"}}/> 

              <Menu theme="dark">
                <Menu.Item key="1" icon={<CompassOutlined />}>
                  <NavLink to="/" className="nav-text">
                    <b style={{ color: "orange" }}>Index</b> 首页
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="2" icon={<ShakeOutlined />}>
                  <NavLink to="/contact" className="nav-text">
                    <b style={{ color: "orange" }}>Contact</b> 联系方式
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="3" icon={<ReadOutlined/>}>
                  <b style={{ color: "orange" }}>English Article</b> 英文文章
                </Menu.Item>
                <Menu.Item key="4" href="/article/" icon={<ReadOutlined/>} >
                  <NavLink to="/article" className="nav-text">
                    <b style={{ color: "orange" }}>Chinese Article</b> 中文文章
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="5" icon={<ProfileOutlined />}>
                <NavLink to="/DevPath" className="nav-text">
                  <b style={{ color: "orange" }}>Dev Path</b> 开发路径
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="6" icon={<ClockCircleOutlined />}>
                  <b style={{ color: "orange" }}>Dev Plan</b> 开发计划
                </Menu.Item>
              </Menu>
            </Sider>

            <Col flex={6} />
            <Col flex={6} style={{width:600}}>
              {this.state.renderContent}
            </Col>
            <Col flex={6}  />
            <Layout className="rightLayout" style={{ minHeight: "12vh" }} />
          </Layout>
        </Row>
        <BackTop />
      </div>
    );
  }
}
