import React from 'react';
import './awsUtil';
import AWS from 'aws-sdk';
import {List} from 'antd';

const s3 = new AWS.S3({
    signatureVersion:'v2',
});



export default class articleList extends React.Component{
    constructor(props){
        super();
        this.state = {
            aList : [],
            renderContent : <div></div>
        }
    }

    async componentDidMount() {
        var that = this;
        var body = {};
        const listData=[];
        console.log("ab")
        var bucketParams = {
            Bucket: "projectwarticle",
        };
         s3.listObjects(bucketParams, function(err, data) {
            if (err) {
                console.log("Error", err);
            }
            else {
                console.log("success",data.Contents);
                body = data.Contents;
                 /*that.setState({
                     aList : data.Contents
                 })*/
                 //console.log(that.state.aList)
                 
                 body.forEach(element => 
                    listData.push({
                        href: '/article/'+element.Key,
                        title: element.Key.replace(".md",""),
                        date: element.LastModified
                    })
                    
                    
                    );

                 that.setState({
                     aList : listData
                 })
                }
            
        });
    }


    



    render(){
        console.log(this.state.aList);
        return(
            <List
    itemLayout="horizontal"
    bordered = {true}
    dataSource={this.state.aList}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          title={<b><a style={{color:"orange"}} href={item.href}>{item.title}</a></b>}
          description={"最后修改日期： "  + item.date.toString()}
        />
      </List.Item>
    )}
  />
  
        )
    }

}