import React from 'react';
import BlogFrame from './blogFrame.jsx'
import './less/global.less'


export default class devPathPage extends React.Component{
    render(){
        return(
            <div>
                <BlogFrame type="log"/>
            </div>
        )
    }
}