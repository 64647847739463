import "./awsUtil";
import '../../../node_modules/highlight.js/styles/dark.css';
import Markdown from "react-markdown";
import React  from 'react';
import AWS from "aws-sdk";
import CodeBlock from './markdown/code-block'
import toc from 'remark-toc'
import {Spin, Space} from 'antd'




const s3 = new AWS.S3({
  signatureVersion: "v2",
});


export default class ArticlePage extends React.Component {
  /*  render(){
        return(
            <div>
            {this.props.match.url}
            </div>
        )
    }*/

  constructor(props) {
    super();
    this.state = {
      title: props.title,
      isLoading: true,
      dataSource: {},
    };
  }

  async componentDidMount() {
    this.setState({
      title: this.props.title,
    });
    var that = this;
    var params = { Bucket: "projectwarticle", Key: this.state.title };

    s3.getObject(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        //console.log(data.Body);
        var body = data.Body.toString("utf-8");
        //var body=data.Body.toString();
        try {
          that.setState({
            isLoading: false,
            dataSource: body,
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  render() {
    console.log(this.state.title);
    //console.log(input);
    //const input = '# This is a header\n\nAnd this is a paragraph'
    if (this.state.isLoading) {
      return <Space size="middle"><Spin size="large" /></Space>;
    } else {
      return (
        
        <span style={{ textAlign: "left" }}>
                      <Markdown
            className="result"
            source={this.state.dataSource}
            skipHtml={this.state.htmlMode === 'skip'}
            escapeHtml={this.state.htmlMode === 'escape'}
            renderers={{code: CodeBlock}}
            plugins={[toc]}
          />

        </span>
      );
    }
  }
}
