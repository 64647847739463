import React from 'react';
import './App.less';
import './comp/MainMenu';
import Router from './Router';
/*
AWS.config.update({region:'us-east-1'});
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:948119bf-6b04-423c-ba43-bf1bba890881',
});
AWS.config.credentials.get(function(err) {
  if (err) console.log(err);  
  else console.log(AWS.config.credentials);
});
*/
//console.log(process.env.AWS_SDK_LOAD_CONFIG);
//process.env.AWS_SDK_LOAD_CONFIG = true;
/*
AWS.config.update({
  region:"us-east-1",
  accessKeyId:"AKIAV6FQIK7QZIEM56GT",
  secretAccessKey:"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"
  //credentials: new AWS.SharedIniFileCredentials()
})*/


function App() {
  document.title="HorizonLight";
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
