import React from 'react';
import {Descriptions } from 'antd'


export default class Contact extends React.Component{

    render(){
        return(
            <div>
                <Descriptions 
                title="Contatc Me"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                
                >
                    <Descriptions.Item label="Name"><b style={{color:"orange"}}>J.Liang</b></Descriptions.Item>
                    <Descriptions.Item label="E-mail"><b style={{color:"orange"}}>j@horizonlight.dev</b></Descriptions.Item>
                    <Descriptions.Item label="Specialty"><b style={{color:"orange"}}>Serverless,React-based Web dev, Android Dev</b></Descriptions.Item>
                    <Descriptions.Item label="Preferred Language"><b style={{color:"orange"}}>Java</b></Descriptions.Item>
                    <Descriptions.Item label="Learned Language"><b style={{color:"orange"}}>NodeJS, C#, Jsx, Python</b></Descriptions.Item>
                </Descriptions>
            </div>
        )
    }
}